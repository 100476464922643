import Navbar from "../Components/Navbar/Navbar";

const Contact = () => {
    return (
        <div>
            <Navbar />
        </div>
    )
}

export default Contact;